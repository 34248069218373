import { createContext, useState, useRef } from "react";
import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";

const ContextState = createContext({});

export const ContextStateProvider = ({ children }) => {
  const onlineStatus = useSelector((state) => state.onlineStatus.online);
  let key = process.env.REACT_APP_IS_LOGIN_KEY;
  const API_URL = process.env.REACT_APP_BACKEND_SERVER;
  const accessTokenKey = process.env.REACT_APP_ACCESS_TOKEN_SECRET;
  const SECRET_VALUE = process.env.REACT_APP_SECRET_VALUE;

  const [accessToken, setaccessToken] = useState("");
  // const [responsedata, setresponsedata] = useState({});
  const [lodingVisiblity, setLoadingVisiblity] = useState(true);
  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [showError, setShowError] = useState({
    status: null,
    msg: null,
  });
  // state to manage modal error page
  const [showModalError, setShowModalError] = useState({
    status: null,
    msg: null,
  });

  // to maintain the status of tickets in modal
  const [ticketStatus, setTicketStatus] = useState(null);

  // snackbar part *****
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const openSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const closeSnackbar = (e, reason) => {
    // console.log(onlineStatus, reason == "clickaway");

    if (reason == "clickaway" && !onlineStatus) {
      return;
    }
    setSnackbarOpen(false);
  };
  // snackbar part *****

  //*********  This part is for encrypted key*********
  // Function to encrypt and set the access token
  const SetNewAccessToken = (accessToken) => {
    const encryptedToken = CryptoJS.AES.encrypt(
      accessToken,
      SECRET_VALUE
    ).toString();
    localStorage.setItem(accessTokenKey, encryptedToken);
  };

  // Function to decrypt and get the access token
  const getAccessToken = () => {
    try {
      const encryptedToken = localStorage.getItem(accessTokenKey);
      if (encryptedToken) {
        // console.log("going inside the above condition")
        const bytes = CryptoJS.AES.decrypt(encryptedToken, SECRET_VALUE);
        const originalToken = bytes.toString(CryptoJS.enc.Utf8);
        return originalToken;
      }
      // console.log("not going inside the above condition")
      return null;
    } catch (error) {
      // console.log("Unable to find access token from local storage :", error);
      localStorage.clear();
      window.location.href = "/login";
    }
  };
  //********* This part is for encrypted key*********

  // showLoading for main pages
  const showLoading = (visiblity) => {
    setLoadingVisiblity(visiblity);
  };
  // showLoading for modal pages
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  // show error page
  const showErrorPage = (status, msg) => {
    showError.status = status;
    showError.msg = msg;
    setShowError(showError);
  };

  // show modal error page
  const showModalErrorPage = (status, msg) => {
    showModalError.status = status;
    showModalError.msg = msg;
    setShowModalError(showModalError);
  };

  // setfiltersData
  const handelSetFilterData = (fData) => {
    setFilterData(fData);
  };

  // setTicketStatus
  const handleSetTicketStatus = (statusData) => {
    setTicketStatus(statusData);
  };

  // this function is to format date into AM/PM format from GMT
  // const formatDate = (inputDate) => {
  //   // const date = new Date(dateString);
  //   // console.log("input date:", inputDate);

  //   inputDate = inputDate?.replace("GMT", "GMT+0530");
  //   const parsedDate = new Date(inputDate);
  //   // console.log("*", new Date());

  //   // Extract AM/PM designation
  //   const ampm = parsedDate.getHours() >= 12 ? "PM" : "AM";

  //   if (parsedDate.getHours() > 12) {
  //     parsedDate.setHours(parsedDate.getHours() - 12);
  //   }

  //   // Format the date to the desired string format
  //   const formattedDate =
  //     parsedDate.toDateString() +
  //     " " +
  //     parsedDate.toLocaleTimeString([], {
  //       hour: "numeric",
  //       minute: "numeric",
  //     }) +
  //     " " +
  //     ampm;

  //   // console.log("date new", inputDate, formattedDate);
  //   return formattedDate;
  // };

  const formatDate = (inputDate, inShort = false) => {
    // Replace "GMT" with "GMT+0530" if necessary
    inputDate = inputDate?.replace("GMT", "GMT+0530");
    const parsedDate = new Date(inputDate);

    if (inShort) {
      // Extract day, month, and year
      const day = parsedDate.getDate().toString().padStart(2, "0");
      const month = parsedDate.toLocaleString("default", { month: "short" });
      const year = parsedDate.getFullYear().toString().slice(-2);

      // Extract hours and minutes in 24-hour format
      const hours = parsedDate.getHours().toString().padStart(2, "0");
      const minutes = parsedDate.getMinutes().toString().padStart(2, "0");

      // Format the final date string as `09Sep'24 17:55`
      const formattedDate = `${day} ${month}'${year} ${hours}:${minutes}`;

      return formattedDate;
    }

    // Extract hours, minutes, and AM/PM designation
    const hours = parsedDate.getHours();
    const minutes = parsedDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    // Format the time string with uppercase AM/PM
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

    // Format the date to a string
    const formattedDate = parsedDate.toDateString() + " " + formattedTime;

    return formattedDate;
  };

  // This function is to convert normal number in indian ruppee format
  const formatInIndianRupees = (number) => {
    return new Intl.NumberFormat("en-IN", {
      // style: "currency",
      // currency: "INR",
      // Optional: You can adjust the minimum and maximum fraction digits
      // minimumFractionDigits: 2,
      // maximumFractionDigits: 2,
    }).format(number);
  };

  //login api call
  const fetchloginApiCall = async (URL, method, payload) => {
    try {
      const response = await fetch(API_URL + URL, {
        method: method,
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
        credentials: "include", //must include it to save it in cookie
      });
      const responseData = await response.json();
      const statusCode = response.status;

      if (statusCode === 200) {
        // localStorage.setItem("accessToken", responseData["access_token"]);
        SetNewAccessToken(responseData["access_token"]);
        localStorage.setItem(key, true);
        return { statusCode, data: responseData };
      } else {
        // alert("Incorrect Login Credentials!!");
        return {
          statusCode,
          msg:
            responseData?.error || "An unexpected issue occurred. Please try again later.",
        };
      }
    } catch (error) {
      // alert("Internal server error");
      return {
        statusCode: 500,
        msg: "An unexpected issue occurred. Please try again later.",
      };
    }
  };

  // logout api call
  const fetchlogoutApiCall = async (URL, method) => {
    try {
      const response = await fetch(API_URL + URL, {
        method: method,
        // headers: headers,
        body: JSON.stringify({}),
        credentials: "include",
      });
      const responseData = await response.json();
      const statusCode = response.status;

      if (statusCode === 200) {
        localStorage.clear();
        // setaccessToken("");
        return { statusCode, data: responseData };
      } else {
        // alert("Some error occured!!!");
        return {
          statusCode,
          msg: responseData?.error || "Internal Server Error!",
        };
      }
    } catch (error) {
      // alert("Internal Server error");
      return {
        statusCode: 500,
        msg: "An unexpected issue occurred. Please try again later.",
      };
    }
  };

  //fetch filter data api call
  const gencontrollerRef = useRef(null);
  const generalApiCall = async (URL, method, payload, isMounted) => {
    try {
      // Pass the AbortController if not provided
      if (gencontrollerRef.current) {
        gencontrollerRef.current.abort();
      }

      gencontrollerRef.current = new AbortController();
      const signal = gencontrollerRef.current.signal;
      const response = await fetch(API_URL + URL, {
        method: method,
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
        signal: signal,
      });

      if (!isMounted) return;

      const responseData = await response.json();
      const statusCode = response.status;

      if (statusCode === 200) {
        return { statusCode, data: responseData };
      } else {
        return { statusCode, msg: responseData?.error || "Some Error Occured" };
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      if (error.name === "AbortError") {
        console.log("Fetch aborted");
        return { statusCode: 200, msg: "Request was aborted" };
      }
      return {
        statusCode: 500,
        data: "An unexpected issue occurred. Please try again later.",
      };
    }
  };

  async function refreshToken(URL, method, payload, isMounted) {
    const refreshTokenFlagKey = "refreshTokenInProgress";
    const refreshTimestampKey = "refreshTokenTimestamp";
    const refreshTimeout = 3000; // Time to wait before retrying refresh (in milliseconds)

    // Check if refresh token is already in progress
    const isRefreshing = localStorage.getItem(refreshTokenFlagKey);
    // if (isRefreshing) {
    //   // Check the timestamp to see if it's recent
    //   const lastRefreshTime = parseInt(
    //     localStorage.getItem(refreshTimestampKey)
    //   );
    //   const currentTime = new Date().getTime();
    //   if (currentTime - lastRefreshTime < refreshTimeout) {
    //     // If recent, wait and retry or notify user
    // setTimeout(fetchApi(URL, method, payload, isMounted), refreshTimeout);
    //     return;
    //   } else {
    //     // If not recent, assume prall_filter_dataevious attempt failed and proceed
    //     localStorage.removeItem(refreshTokenFlagKey);
    //   }
    // }

    // Set refresh token in progress flag
    localStorage.setItem(refreshTokenFlagKey, true);
    localStorage.setItem(refreshTimestampKey, new Date().getTime());

    try {
      const response = await fetch(API_URL + "refresh_user_token", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({}),
      });

      const responseData = await response.json();
      const statusCode = response.status;
      // console.log("responseData", responseData, statusCode);
      // if (!response.ok) {
      //   throw new Error("Failed to refresh token");
      // }

      if (statusCode === 200) {
        const newAccessToken = responseData.access_token;

        // Update token in local storage
        // localStorage.setItem("accessToken", newAccessToken);
        SetNewAccessToken(newAccessToken);

        // Remove refresh token in progress flag
        localStorage.removeItem(refreshTokenFlagKey);

        return fetchApi(URL, method, payload, isMounted);
      } else if (statusCode === 500) {
        // console.log("Internal Server error ", responseData);
        // window.location.href("/login")
        return { statusCode: statusCode, msg: responseData.error };
      } else if (
        statusCode === 401 &&
        responseData.error.toLowerCase() === "Expired JWT token".toLowerCase()
      ) {
        // return expired div
        showErrorPage(statusCode, responseData.error);
        return { statusCode: statusCode, msg: responseData.error };
      } else {
        // route login
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      // window.location.href("/login")
      localStorage.removeItem(refreshTokenFlagKey);
      return {
        statusCode: 500,
        msg: "An unexpected issue occurred. Please try again later.",
      };
    }
  }
  const controllerRef = useRef(null);
  const modalControllerRef = useRef(null); // For modal-specific API requests
  let currentEndpoint = null; // Track the ongoing request's endpoint
  async function fetchApi(
    URL,
    method,
    payload,
    isMounted,
    abortRef = 0,
    showAbortSnackbar = false
  ) {
    const accessToken = getAccessToken();
    // if (!isMounted) {
    //   return;
    // }

    // Abort the previous request, if it's still ongoing
    if (abortRef === 0 && controllerRef.current) {
      controllerRef.current.abort();
    }
    if (abortRef === 1 && modalControllerRef.current) {
      modalControllerRef.current.abort();
    }

    // Determine which signal to use based on the type of request (modal or general)
    let signal;
    if (abortRef === 1) {
      modalControllerRef.current = new AbortController();
      signal = modalControllerRef.current.signal;
    } else if (abortRef === 0) {
      controllerRef.current = new AbortController();
      signal = controllerRef.current.signal;
    }

    if (window.location.pathname !== currentEndpoint) {
      currentEndpoint = window.location.pathname;
    }
    try {
      const response = await fetch(API_URL + URL, {
        method: method,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
        signal: signal,
      });
      // console.log("Data fetched successfully:", response);

      const responseData = await response.json();
      const statusCode = response.status;
      // console.log("statusCode : ", statusCode)
      if (statusCode === 200) {
        return { statusCode, data: responseData };
      } else if (statusCode === 401 || statusCode === 422) {
        // console.log("Token has expired. Attempting to refresh access token...");
        // Call your token refresh function here
        return refreshToken(URL, method, payload, isMounted);
      } else {
        // console.log("Unexpected error. Status code:", statusCode);
        // localStorage.clear();
        // window.location.href ="/login";
        return {
          statusCode: statusCode,
          msg: responseData.error || "Some Error Occured",
        };
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      if (error.name === "AbortError") {
        console.log("Fetch aborted");
        // if (currentEndpoint === window.location.pathname && showAbortSnackbar) {
        //   openSnackbar(
        //     "A previous request is still in progress. Please wait before making a new request.",
        //     "warning"
        //   );
        // }
      } else {
        // window.location.href("/login")
        return {
          statusCode: 500,
          msg: "Oops! It looks like there's an internal server error. Please try again later...",
        };
      }
    }
  }

  return (
    <ContextState.Provider
      value={{
        url: API_URL,
        key,
        formatDate,
        formatInIndianRupees,
        fetchloginApiCall,
        fetchlogoutApiCall,
        generalApiCall,
        accessToken,
        showLoading,
        lodingVisiblity,
        handelSetFilterData,
        filterData,
        fetchApi,
        showError,
        showErrorPage,
        showModalError,
        showModalErrorPage,
        ticketStatus,
        handleSetTicketStatus,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        openSnackbar,
        closeSnackbar,
        lodingModalVisiblity,
        showLoadingModal,
        SetNewAccessToken,
        gencontrollerRef,
        controllerRef,
        modalControllerRef,
      }}
    >
      {children}
    </ContextState.Provider>
  );
};

export default ContextState;
