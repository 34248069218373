import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField, Tooltip, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ContextState from "../contextApi/ContextState";
import dayjs from "dayjs";
import "../../assets/styles/UpdateMachineLocation.css";
import SendIcon from "@mui/icons-material/Send";
import { addTicketDetail } from "../features/filtersSlice/filterSlice";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import color from "../../Components/modals/Calllog";
import { AiOutlineDelete } from "react-icons/ai";
import AddIcon from "@mui/icons-material/Add";

const filter = createFilterOptions();

const UpdateMachine = () => {
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  const {
    fetchApi,
    showLoading,
    showError,
    showErrorPage,
    openSnackbar,
    controllerRef,
    formatInIndianRupees,
  } = useContext(ContextState);

  const dispatch = useDispatch();
  const createTicketDetail = useSelector((state) => state.createTicketDetail);

  const [loading, setloading] = useState(false);

  const [activeButton, setActiveButton] = useState("addMachine");

  const options = [
    { label: "Add Machine", value: "addMachine" },
    { label: "Update Machine", value: "updateMachine" },
  ];

  const [machineInfo, setMachineInfo] = useState({
    new_region: "",
    new_district: "",
    old_location: "",
    new_location: "",
    code: "",
    address: "",
    IsNewLocation: false,
    IsNewDistrict: false,
    IsNewRegion: false,
    date: "",
    remark: "",
    visit_id: "",
    shifted_by: "",
  });

  const [machineAddInfo, setMachineAddInfo] = useState({
    new_client: "",
    new_region: "",
    new_district: "",
    new_location: "",
    IsNewClient: false,
    IsNewRegion: false,
    IsNewDistrict: false,
    IsNewLocation: false,
    code: "",
    address: "",
    is_new_client_disabled: false,
  });

  const [finalMachineAddInfo, setFinalMachineAddInfo] = useState([]);

  const [getcreateTicketDetail, setgetcreateTicketDetail] = useState(null);
  // console.log("filters data", getcreateTicketDetail);
  const submitForm = async () => {
    try {
      const payload = {
        new_region: machineInfo?.new_region,
        new_district: machineInfo?.new_district,
        old_location: machineInfo?.old_location,
        new_location: machineInfo?.new_location,
        code: machineInfo?.code,
        address: machineInfo?.address,
        IsNewLocation: machineInfo?.IsNewLocation,
        IsNewDistrict: machineInfo?.IsNewDistrict,
        IsNewRegion: machineInfo?.IsNewRegion,
        date: machineInfo?.date
          ? dayjs(machineInfo?.date).format("YYYY-MM-DD")
          : "",
        remark: machineInfo?.remark,
        shifted_by: machineInfo?.shifted_by,
      };

      if (machineInfo?.visit_id !== "") {
        payload["visit_id"] = machineInfo?.visit_id;
      }

      // console.log("---payload---", payload);

      setloading(true);

      // Send the payload to the backend route
      const response = await fetchApi(
        "update_machine_info",
        "POST",
        payload,
        true
      );
      if (response.statusCode === 200) {
        // console.log("comments added succesfully");
        setMachineInfo((prev) => ({
          ...prev,
          new_region: "",
          new_district: "",
          old_location: "",
          new_location: "",
          code: "",
          address: "",
          IsNewLocation: false,
          IsNewDistrict: false,
          IsNewRegion: false,
          date: "",
          remark: "",
          visit_id: "",
          shifted_by: "",
        }));
        openSnackbar(response.data.message, "success");
      } else {
        // console.error("Error editing ticket information:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
      // console.log("addded new model details", modelDetails);
    } catch (error) {
      // console.error("Error editing ticket information:", error);
      openSnackbar("some error occured while saving details", "error");
    } finally {
      setloading(false);
    }
  };

  const getFilterResponse = async (response) => {
    try {
      if (response && response?.data) {
        const data = response.data;
        setgetcreateTicketDetail(data);
      } else {
        console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchFiltersData = async () => {
    const currentTime = Date.now();
    const lastDataFetchTimestamp = createTicketDetail?.lastDataFetchTimestamp;

    let is_create_ticket_data_Mounted = true;
    if (
      !lastDataFetchTimestamp ||
      currentTime - lastDataFetchTimestamp >= 30 * 60 * 1000
    ) {
      // fetchFiltersDataApiCall
      const create_ticket_data = await fetchApi(
        "get_create_ticket_details",
        "POST",
        {},
        is_create_ticket_data_Mounted
      );
      // console.log("create_ticket_data in All Ticket : ", create_ticket_data);

      if (create_ticket_data.statusCode === 200) {
        dispatch(addTicketDetail(create_ticket_data?.data));
        getFilterResponse(create_ticket_data);
        showErrorPage(null, null);
      } else if (
        create_ticket_data.statusCode === 500 ||
        create_ticket_data.statusCode === 401
      ) {
        showErrorPage(create_ticket_data.statusCode, create_ticket_data.msg);
      } else {
        localStorage.clear();
        window.location.href = "/login";
      }
    } else {
      // console.log("Data fetch from redux ************", createTicketDetail);
      getFilterResponse(createTicketDetail);
    }
  };

  const addPrevRoDo = () => {
    if (machineInfo.code !== "") {
      let found = false;
      for (const [region, districts] of Object.entries(
        getcreateTicketDetail?.filters_data
      )) {
        for (const [district, codes] of Object.entries(districts)) {
          if (codes.includes(machineInfo.code)) {
            setMachineInfo((prevInfo) => ({
              ...prevInfo,
              new_region: region,
              new_district: district,
            }));
            found = true;
            break;
          }
        }
        if (found) break;
      }
    }
  };

  const handleButtonClick = (value) => {
    setActiveButton(value);
  };

  // console.log("machine info", machineInfo);

  useEffect(() => {
    document.title = "Update Machine Locations- NEO";
    showLoading(true);
    fetchFiltersData();
    showLoading(false);
    return () => {
      // On unmount, abort the previous request for fetchApi api
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  // options for previous machine location list...
  const shifted_by_options =
    (getcreateTicketDetail &&
      getcreateTicketDetail?.assignee_list?.map(
        (each_assigne) => each_assigne.name
      )) ||
    [];

  shifted_by_options.push("Self Shifted by Fci");

  // function to set the automatic location data according to the machine code
  function get_location_data(code) {
    let location_name = createTicketDetail?.data?.machines_data.find(
      (machine_code) => {
        if (machine_code?.code === code) {
          setMachineInfo((prev) => ({
            ...prev,
            old_location: machine_code.location,
          }));
        }
      }
    );
  }

  // data grid Table
  const handleAddMachineData = () => {
    setFinalMachineAddInfo((prev) => [
      ...prev,
      {
        new_client: machineAddInfo.new_client,
        new_region: machineAddInfo.new_region,
        new_district: machineAddInfo.new_district,
        new_location: machineAddInfo.new_location,
        code: machineAddInfo.code,
        address: machineAddInfo.address,
      },
    ]);

    // Reset `machineAddInfo` to default values
    setMachineAddInfo((prev) => ({
      ...prev,
      // new_client: "",
      // new_region: "",
      // new_district: "",
      new_location: "",
      // IsNewClient: false,
      // IsNewRegion: false,
      // IsNewDistrict: false,
      IsNewLocation: false,
      code: "",
      address: "",
      is_new_client_disabled: true,
    }));

    openSnackbar("Added machine details successfully", "success");
  };

  const columns = [
    { field: "new_client", headerName: "Client", width: 100 },
    { field: "new_region", headerName: "Region", width: 100 },
    { field: "new_district", headerName: "District", width: 100 },
    { field: "new_location", headerName: "Location", width: 100 },
    { field: "code", headerName: "Machine", width: 100 },
    { field: "address", headerName: "Address", width: 240 },
  ];

  const rows =
    finalMachineAddInfo?.length > 0 &&
    finalMachineAddInfo?.map((each_row, index) => ({
      id: index + 1,
      new_client: each_row.new_client,
      new_region: each_row.new_region,
      new_district: each_row.new_district,
      new_location: each_row.new_location,
      code: each_row.code,
      address: each_row.address,
    }));

  const handleDelete = (row) => {
    const result = finalMachineAddInfo.filter((item) => item.code !== row.code);

    setFinalMachineAddInfo(result);

    openSnackbar("Deleted selected row successfully", "warning");
  };

  function getFirstPageDataTableDataHeader(data) {
    const headers = [];
    const rows = [];

    if (data.rows.length > 0) {
      data.column.forEach((item, index) => {
        if (!["uuid", "notes"].includes(item.field)) {
          headers.push({
            field: item.field,
            headerName: item.headerName,
            width: item.width,
            editable: item.editable,
            flex: isMediumScreen ? 0 : 1,
            headerAlign: index === 0 ? "left" : "center",
            renderHeader: (params) => (
              <Tooltip title={item.headerName} arrow>
                <span style={{ paddingLeft: index === 0 ? "10px" : "0px" }}>
                  {params.colDef.headerName}
                </span>
              </Tooltip>
            ),
            renderCell: (params) => {
              // console.log("params-->: ", params);
              const finalParamValue = ["quantity", "price"].some(
                (items) => item.field === items && params.value !== ""
              )
                ? formatInIndianRupees(params.value)
                : ["quantity"].some(
                    (items) => items === item.field && params.value === ""
                  )
                ? "1"
                : ["serial_number"].some(
                    (items) => items === item.field && params.value === ""
                  )
                ? "-"
                : params.value;
              return (
                <Box
                  sx={{
                    textAlign: index !== 0 ? "center" : "left",
                    fontFamily: "Open-Sans-Medium",
                    fontWeight: (index !== 0 && "500") || "700",
                    fontSize: item.field === "status" ? "0.85rem" : "1.05rem",
                    color: color.tableRowHeading,
                    textWrap: "nowrap",
                  }}
                >
                  {
                    <Tooltip
                      title={
                        <>
                          <div
                            style={{
                              fontSize: "0.85rem",
                              fontFamily: "Open-Sans-Medium",
                            }}
                          >
                            <div>
                              <strong style={{ textTransform: "capitalize" }}>
                                {params.field.split("_").join(" ")}
                              </strong>
                              :{" "}
                              <strong
                                style={{
                                  textTransform: "none",
                                }}
                              >
                                {finalParamValue}
                              </strong>
                            </div>
                          </div>
                        </>
                      }
                      arrow
                    >
                      <span
                        style={{
                          // border: "2px solid blue",
                          padding: "4px 9px",
                          borderRadius: "4px",
                          textTransform: "none",
                        }}
                      >
                        {finalParamValue}
                      </span>
                    </Tooltip>
                  }
                </Box>
              );
            },
          });
        }
      });

      // deletion column
      headers.push({
        field: "delete",
        headerName: "Action",
        width: 100,
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <Box sx={{ textAlign: "center", padding: "8px" }}>
              {/* <Tooltip title="Remove product from list." arrow> */}
              <div
                style={{
                  cursor: "pointer",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  margin: "0px auto",
                }}
              >
                <Tooltip title="Delete">
                  <div
                    className="dropDown_btns lot_btn_delete"
                    onClick={() => handleDelete(params.row)}
                  >
                    <AiOutlineDelete
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "0px auto",
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            </Box>
          );
        },
      });
    }

    data.rows.forEach((item, index) => {
      rows.push({ ...item, id: index + 1 });
    });

    // console.log("headers , rows ", headers, rows);

    return { TableDataHeaders: headers, TabelDataRows: rows };
  }

  const { TableDataHeaders, TabelDataRows } = (columns &&
    rows?.length !== 0 &&
    getFirstPageDataTableDataHeader({
      rows: rows || [],
      column: columns || [],
    })) || { TableDataHeaders: [], TabelDataRows: [] };

  // client list

  const clientList = ["FCI", "ENAM"];

  const fetchAddMachinesAPi = async () => {
    try {
      setloading(true);
      let final_payload = finalMachineAddInfo.map((item) => ({
        region: item.new_region,
        district: item.new_district,
        location: item.new_location,
        code: item.code,
        address: item.address === "" ? null : item.address,
      }));

      const payload = {
        client: finalMachineAddInfo[0].new_client,
        data: final_payload,
      };
      // console.log(payload);
      const response = await fetchApi("add_machine", "POST", payload, true);
      if (response.statusCode === 200) {
        // reset of final state
        setFinalMachineAddInfo([]);

        setMachineAddInfo((prev) => ({
          new_client: "",
          new_region: "",
          new_district: "",
          new_location: "",
          IsNewClient: false,
          IsNewRegion: false,
          IsNewDistrict: false,
          IsNewLocation: false,
          code: "",
          address: "",
          is_new_client_disabled: false,
        }));

        openSnackbar(response?.data?.message, "success");
      } else {
        openSnackbar(response?.msg, "error");
      }
    } catch (error) {
      openSnackbar("Internal Server Error!", "error");
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div
          className="tag-options style-scrollbar"
          style={{ overflowX: "auto", marginTop: "20px" }}
        >
          {options.map((option, index) => (
            <div className="tag-option-button" key={index}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleButtonClick(option.value);
                }}
                className={activeButton === option.value ? "active" : ""}
              >
                {option.label}
              </button>
            </div>
          ))}
        </div>

        {/* add machine part start from here  */}
        {activeButton === "addMachine" && (
          <div className="m-0 p-0">
            {/* <div
              className="mb-3 mt-4 ms-3"
              style={{
                color: "rgba(76,78,100,0.7)",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              <span className="fs-5">Add New Machines</span>
            </div> */}
            <div
              className=""
              style={{
                width: "92%",
                margin: "20px auto",
                maxWidth: "1200px",
                minHeight: "180px",
                backgroundColor: "#FFFFFF",
                padding: "35px 35px 25px",
                borderRadius: 12,
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 16px 0px",
              }}
            >
              <div
                className="add_new_machine "
                // style={{ width: "100%" }}
              >
                {/* Choose Client  */}

                {/* Add Client */}
                <Autocomplete
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      // padding: "5px",
                      width: "100%",
                    },
                  }}
                  disablePortal
                  disabled={machineAddInfo.is_new_client_disabled}
                  size="medium"
                  id="Choose Client"
                  options={clientList || []}
                  value={machineAddInfo?.new_client}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Client *" />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setMachineAddInfo((prev) => ({
                        ...prev,
                        new_client: newValue,
                      }));
                    } else {
                      // Handle clearing of the selection
                      setMachineAddInfo((prev) => ({
                        ...prev,
                        new_client: "",
                        new_region: "",
                        new_district: "",
                        new_location: "",
                      }));
                    }
                  }}
                />

                {/* Add Region */}
                <Autocomplete
                  value={machineAddInfo?.new_region}
                  onChange={(event, newValue) => {
                    setMachineAddInfo((prev) => ({
                      ...prev,
                      new_district: "",
                    }));
                    if (newValue) {
                      if (
                        typeof newValue === "string" &&
                        newValue.startsWith('Add "')
                      ) {
                        setMachineAddInfo((prev) => ({
                          ...prev,
                          new_region: newValue.slice(5, -1),
                          IsNewRegion: true,
                        }));
                      } else {
                        setMachineAddInfo((prev) => ({
                          ...prev,
                          new_region: newValue,
                          IsNewRegion: false,
                        }));
                      }
                    } else {
                      setMachineAddInfo((prev) => ({
                        ...prev,
                        new_region: "",
                        IsNewRegion: false,
                      }));
                    }
                  }}
                  options={
                    machineAddInfo.new_client === "FCI"
                      ? Object.keys(getcreateTicketDetail?.filters_data || [])
                      : machineAddInfo.new_client === "ENAM"
                      ? Object.keys(
                          getcreateTicketDetail?.enam_filters_data || []
                        )
                      : []
                  }
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;

                    // Normalize the inputValue to lowercase
                    const normalizedInputValue = inputValue.toLowerCase();

                    // Check if the inputValue matches any existing option (case-insensitive)
                    const isExisting = options.some(
                      (option) => option.toLowerCase() === normalizedInputValue
                    );

                    // Suggest the creation of a new value only if it does not already exist
                    if (inputValue !== "" && !isExisting) {
                      filtered.push(`Add "${inputValue}"`);
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="Choose Region"
                  getOptionLabel={(option) => {
                    // Regular option
                    if (typeof option === "string") {
                      return option;
                    }
                    return option.toString();
                  }}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  sx={{ width: "100%" }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Region *" />
                  )}
                />

                {/* Add District */}
                <Autocomplete
                  value={machineAddInfo?.new_district}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      if (
                        typeof newValue === "string" &&
                        newValue.startsWith('Add "')
                      ) {
                        setMachineAddInfo((prev) => ({
                          ...prev,
                          new_district: newValue.slice(5, -1),
                          IsNewDistrict: true,
                        }));
                      } else {
                        setMachineAddInfo((prev) => ({
                          ...prev,
                          new_district: newValue,
                          IsNewDistrict: false,
                        }));
                      }
                    } else {
                      setMachineAddInfo((prev) => ({
                        ...prev,
                        new_district: "",
                        IsNewDistrict: false,
                      }));
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;

                    // Normalize the inputValue to lowercase
                    const normalizedInputValue = inputValue.toLowerCase();

                    // Check if the inputValue matches any existing option (case-insensitive)
                    const isExisting = options.some(
                      (option) => option.toLowerCase() === normalizedInputValue
                    );

                    // Suggest the creation of a new value only if it does not already exist
                    if (inputValue !== "" && !isExisting) {
                      filtered.push(`Add "${inputValue}"`);
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="Choose District"
                  // options={
                  //   (getcreateTicketDetail &&
                  //     Object.values(
                  //       getcreateTicketDetail?.filters_data
                  //     ).flatMap(Object.keys)) ||
                  //   []
                  // }

                  options={
                    machineAddInfo.new_client === "FCI"
                      ? Object.values(
                          getcreateTicketDetail?.filters_data
                        ).flatMap(Object.keys) || []
                      : machineAddInfo.new_client === "ENAM"
                      ? Object.values(
                          getcreateTicketDetail?.enam_filters_data
                        ).flatMap(Object.keys) || []
                      : []
                  }
                  getOptionLabel={(option) => {
                    // Regular option
                    if (typeof option === "string") {
                      return option;
                    }
                    return option.toString();
                  }}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  sx={{ width: "100%" }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="Choose District *" />
                  )}
                />

                {/* Add location */}
                <Autocomplete
                  value={machineAddInfo?.new_location}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      if (
                        typeof newValue === "string" &&
                        newValue.startsWith('Add "')
                      ) {
                        setMachineAddInfo((prev) => ({
                          ...prev,
                          new_location: newValue.slice(5, -1),
                          IsNewLocation: true,
                        }));
                      } else {
                        setMachineAddInfo((prev) => ({
                          ...prev,
                          new_location: newValue,
                          IsNewLocation: false,
                        }));
                      }
                    } else {
                      setMachineAddInfo((prev) => ({
                        ...prev,
                        new_location: "",
                        IsNewLocation: false,
                      }));
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;

                    // Normalize the inputValue to lowercase
                    const normalizedInputValue = inputValue.toLowerCase();

                    // Check if the inputValue matches any existing option (case-insensitive)
                    const isExisting = options.some(
                      (option) => option.toLowerCase() === normalizedInputValue
                    );

                    // Suggest the creation of a new value only if it does not already exist
                    if (inputValue !== "" && !isExisting) {
                      filtered.push(`Add "${inputValue}"`);
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="Choose Location"
                  // options={
                  //   (getcreateTicketDetail &&
                  //     getcreateTicketDetail?.complete_locations) ||
                  //   []
                  // }

                  options={
                    machineAddInfo?.new_client === "FCI"
                      ? getcreateTicketDetail?.complete_locations || []
                      : machineAddInfo?.new_client === "ENAM"
                      ? getcreateTicketDetail?.enam_complete_locations || []
                      : []
                  }
                  getOptionLabel={(option) => {
                    // Regular option
                    if (typeof option === "string") {
                      return option;
                    }
                    return option.toString();
                  }}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  sx={{ width: "100%" }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose Location *"
                      placeholder="Enter Location Name"
                    />
                  )}
                />

                {/* Add Machine  */}
                <TextField
                  value={machineAddInfo?.code}
                  id="outlined-basic"
                  label="Add Machine Code *"
                  placeholder="Enter Machine Code"
                  variant="outlined"
                  onChange={(event) => {
                    setMachineAddInfo((prev) => ({
                      ...prev,
                      code: event.target.value,
                    }));
                  }}
                />

                {/* Add address  */}
                <TextField
                  value={machineAddInfo?.address}
                  fullWidth
                  id="outlined-basic"
                  label="Add Address *"
                  placeholder="For Ex: Anji tiffins, 800042, Nagarjuna Hills, Hyderabad, Telangana"
                  variant="outlined"
                  onChange={(event) => {
                    setMachineAddInfo((prev) => ({
                      ...prev,
                      address: event.target.value,
                    }));
                  }}
                />
              </div>
              <div className="d-flex justify-content-end my-4">
                <LoadingButton
                  size="medium"
                  onClick={() => handleAddMachineData()}
                  disabled={
                    machineAddInfo?.new_client === "" ||
                    machineAddInfo?.new_region === "" ||
                    machineAddInfo?.new_district === "" ||
                    machineAddInfo?.new_location === "" ||
                    machineAddInfo?.code === ""
                  }
                  startIcon={<AddIcon />}
                  variant="contained"
                  style={{ height: "40px" }}
                >
                  <span>Add</span>
                </LoadingButton>
              </div>

              {/* data grid table for the add machine  */}
              {finalMachineAddInfo.length > 0 && (
                <div
                  style={{
                    border: "1px solid #e5e6e8",
                    borderRadius: "12px",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      padding: "20px 20px 25px",
                    }}
                  >
                    Add Machine Details
                  </div>
                  <Box
                    sx={{
                      maxHeight: "60vh",
                    }}
                  >
                    <DataGrid
                      rows={TabelDataRows}
                      columns={TableDataHeaders}
                      rowHeight={62}
                      columnHeaderHeight={62}
                      density="standard"
                      paginationMode="client"
                      disableSelectionOnClick
                      disableRowSelectionOnClick
                      disableColumnFilter
                      sx={{
                        "&.MuiDataGrid-root": {
                          border: "none",
                          // borderRadius: "5px", // Adjust border radius as needed
                          overflow: "hidden", // Ensure content respects the border radius,
                          padding: "0px",
                          margin: "0px",
                          marginTop: "-10px",
                          borderColor: "white",
                        },

                        ".MuiDataGrid-columnHeader": {
                          backgroundColor: "#f5f5f7",
                          border: "none",
                        },
                        ".MuiDataGrid-filler": {
                          backgroundColor: color.textWhiteColor,
                        },
                        ".MuiDataGrid-columnHeaderTitle": {
                          fontFamily: "Open-Sans-Light",
                          fontWeight: "bold",
                          padding: "5px 0px",
                          fontSize: "0.85rem",
                          textTransform: "capitalize",
                          color: color.taxtHeading,
                        },
                        ".MuiDataGrid-columnHeader--alignCenter": {
                          headerAlign: "left",
                        },
                        ".MuiDataGrid-row": {
                          border: "none",
                          backgroundColor: color.textWhiteColor,
                          "&:hover": {
                            backgroundColor: color.primary,
                          },
                        },
                        ".MuiDataGrid-footerContainer": {
                          // display: (visibleRows <= 50 && "none") || "inline-block", // Hide the pagination footer
                          justifyContent: "space-between", // Space out selected row count and pagination controls
                          alignItems: "center", // Vertically align items in the footer
                          marginBottom: "-5px", // Reduce this value to decrease the bottom margin for the footer,
                          height: "30px",
                        },
                        ".MuiTablePagination-root": {
                          // ".MuiInputBase-root": {
                          //   display: "none",
                          // },

                          // ".MuiTablePagination-selectLabel": {
                          //   display: "none",
                          // },
                          ".MuiToolbar-root": {
                            marginTop: "0px",
                          },
                        },
                        ".MuiDataGrid-cell": {
                          // backgroundColor: color.textWhiteColor, // Set background color to red for out-of-range values
                          outline: "none",
                          // display: "-webkit-box",
                          // WebkitBoxOrient: "vertical",
                          // WebkitLineClamp: 2, // Adjust the number of lines you want to show
                          // overflow: "hidden",
                          // textOverflow: "ellipsis",
                          "&:focus": {
                            outline: "none", // Remove the blue outline when a cell is focused
                          },
                        },
                        "&.selected-row": {
                          backgroundColor: "red", // Highlight color for the selected row
                          "&:hover": {
                            backgroundColor: "red ", // Ensure highlight color remains on hover
                          },
                        },
                        ".MuiDataGrid-selectedRowCount": {
                          display: "none", // Hide the selected row count div
                        },
                        ".MuiTablePagination-toolbar": {
                          // width: "80%",  // Adjust the width here
                          // margin: "0 auto", // Center align the pagination
                          // marginLeft:"auto",
                          padding: "2px 16px", // You can adjust padding as well if needed
                          marginTop: "-15px",
                        },
                        "& .MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader":
                          {
                            borderBottom: "none",
                          },
                        "& .MuiDataGrid-row--borderBottom .MuiDataGrid-filler":
                          {
                            borderBottom: "none",
                          },
                        "& .MuiDataGrid-row--borderBottom .MuiDataGrid-scrollbarFiller":
                          {
                            borderBottom: "none",
                          },
                        ".MuiDataGrid-columnSeparator--resizable": {
                          display: "none",
                        },
                        ".MuiDataGrid-menuOpen": {
                          visibility: "hidden",
                        },
                        ".MuiDataGrid-menuIcon": {
                          display: "none",
                        },

                        ".MuiTablePagination-selectLabel": {
                          display: "none",
                        },

                        ".MuiTablePagination-input": {
                          display: "none",
                        },
                      }}
                    />
                  </Box>
                </div>
              )}

              {/* add machine final submit and hitting api */}
              {finalMachineAddInfo.length > 0 && (
                <div className=" d-flex justify-content-end mt-4">
                  <LoadingButton
                    size="medium"
                    onClick={() => fetchAddMachinesAPi()}
                    disabled={!finalMachineAddInfo.length > 0}
                    endIcon={<SendIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    style={{ height: "40px" }}
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </div>
              )}
            </div>
          </div>
        )}

        {/* update machine part start from here  */}
        {activeButton === "updateMachine" && (
          <div className="m-0 p-0">
            {/* <div
              className="mb-3 mt-4 ms-3"
              style={{
                color: "rgba(76,78,100,0.7)",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              <span className="fs-5">Update Machine Locations</span>
            </div> */}
            <div
              className=""
              style={{
                width: "92%",
                margin: "20px auto",
                maxWidth: "1200px",
                minHeight: "180px",
                backgroundColor: "#FFFFFF",
                padding: "25px",
                borderRadius: 12,
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 16px 0px",
              }}
            >
              <div
                className="update_machine_location "
                // style={{ width: "100%" }}
              >
                {/* Choose Machines  */}
                <Autocomplete
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      // padding: "5px",
                      width: "100%",
                    },
                  }}
                  disablePortal
                  size="medium"
                  id="Choose Machine"
                  options={
                    (getcreateTicketDetail &&
                      getcreateTicketDetail?.machines_data?.map(
                        (item, index) => {
                          return item.code;
                        }
                      )) ||
                    []
                  }
                  value={machineInfo?.code}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Machine*" />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setMachineInfo((prev) => ({
                        ...prev,
                        code: newValue,
                      }));
                      get_location_data(newValue);
                    } else {
                      // Handle clearing of the selection
                      setMachineInfo((prev) => ({
                        ...prev,
                        code: "",
                        new_region: "",
                        new_district: "",
                        IsNewDistrict: false,
                        IsNewRegion: false,
                      }));
                    }
                  }}
                />

                {/* Choose old location  */}
                <Autocomplete
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      // padding: "5px",
                      width: "100%",
                    },
                  }}
                  disablePortal
                  size="medium"
                  id="Choose Old Location"
                  options={
                    (getcreateTicketDetail &&
                      getcreateTicketDetail?.complete_locations) ||
                    []
                  }
                  value={machineInfo?.old_location}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Old Location*" />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setMachineInfo((prev) => ({
                        ...prev,
                        old_location: newValue,
                      }));
                    } else {
                      setMachineInfo((prev) => ({
                        ...prev,
                        old_location: "",
                      }));
                    }
                  }}
                />

                {/* Choose new location */}
                <div className="position-relative">
                  <Autocomplete
                    value={machineInfo?.new_location}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        if (
                          typeof newValue === "string" &&
                          newValue.startsWith('Add "')
                        ) {
                          setMachineInfo((prev) => ({
                            ...prev,
                            new_location: newValue.slice(5, -1),
                            IsNewLocation: true,
                          }));
                        } else {
                          setMachineInfo((prev) => ({
                            ...prev,
                            new_location: newValue,
                            IsNewLocation: false,
                          }));
                        }
                      } else {
                        setMachineInfo((prev) => ({
                          ...prev,
                          new_location: "",
                          IsNewLocation: false,
                        }));
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;

                      // Normalize the inputValue to lowercase
                      const normalizedInputValue = inputValue.toLowerCase();

                      // Check if the inputValue matches any existing option (case-insensitive)
                      const isExisting = options.some(
                        (option) =>
                          option.toLowerCase() === normalizedInputValue
                      );

                      // Suggest the creation of a new value only if it does not already exist
                      if (inputValue !== "" && !isExisting) {
                        filtered.push(`Add "${inputValue}"`);
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="Choose New Location"
                    options={
                      (getcreateTicketDetail &&
                        getcreateTicketDetail?.complete_locations) ||
                      []
                    }
                    getOptionLabel={(option) => {
                      // Regular option
                      if (typeof option === "string") {
                        return option;
                      }
                      return option.toString();
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option}</li>
                    )}
                    sx={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Choose New Location*" />
                    )}
                  />
                  {(machineInfo?.code !== "" &&
                    machineInfo?.new_location !== "" && (
                      <Tooltip
                        data-html="true"
                        title={
                          "Click if new location has same DO & RO as the previous location."
                        }
                        arrow={true}
                      >
                        <span
                          className="position-absolute "
                          style={{
                            boxShadow: "0px 0px 2px 0px gray",
                            padding: "1px 8px",
                            fontFamily: "Open-Sans-regular",
                            fontSize: "0.65rem",
                            margin: "2px 4px",
                            marginRight: "30px",
                            right: -25,
                            bottom: -22,
                            borderRadius: "4px",
                            backgroundColor: "rgb(232 233 235 / 30%);",
                            color: "#0d6efd",
                            cursor: "pointer",
                          }}
                          onClick={addPrevRoDo}
                        >
                          Select Prev RO & DO
                        </span>
                      </Tooltip>
                    )) ||
                    ""}
                </div>

                {/* Choose new District */}
                <Autocomplete
                  value={machineInfo?.new_district}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      if (
                        typeof newValue === "string" &&
                        newValue.startsWith('Add "')
                      ) {
                        setMachineInfo((prev) => ({
                          ...prev,
                          new_district: newValue.slice(5, -1),
                          IsNewDistrict: true,
                        }));
                      } else {
                        setMachineInfo((prev) => ({
                          ...prev,
                          new_district: newValue,
                          IsNewDistrict: false,
                        }));
                      }
                    } else {
                      setMachineInfo((prev) => ({
                        ...prev,
                        new_district: "",
                        IsNewDistrict: false,
                      }));
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;

                    // Normalize the inputValue to lowercase
                    const normalizedInputValue = inputValue.toLowerCase();

                    // Check if the inputValue matches any existing option (case-insensitive)
                    const isExisting = options.some(
                      (option) => option.toLowerCase() === normalizedInputValue
                    );

                    // Suggest the creation of a new value only if it does not already exist
                    if (inputValue !== "" && !isExisting) {
                      filtered.push(`Add "${inputValue}"`);
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="Choose New District"
                  options={
                    (getcreateTicketDetail &&
                      Object.values(
                        getcreateTicketDetail?.filters_data
                      ).flatMap(Object.keys)) ||
                    []
                  }
                  getOptionLabel={(option) => {
                    // Regular option
                    if (typeof option === "string") {
                      return option;
                    }
                    return option.toString();
                  }}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  sx={{ width: "100%" }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="Choose New District*" />
                  )}
                />

                {/* Choose new Region */}
                <Autocomplete
                  value={machineInfo?.new_region}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      if (
                        typeof newValue === "string" &&
                        newValue.startsWith('Add "')
                      ) {
                        setMachineInfo((prev) => ({
                          ...prev,
                          new_region: newValue.slice(5, -1),
                          IsNewRegion: true,
                        }));
                      } else {
                        setMachineInfo((prev) => ({
                          ...prev,
                          new_region: newValue,
                          IsNewRegion: false,
                        }));
                      }
                    } else {
                      setMachineInfo((prev) => ({
                        ...prev,
                        new_region: "",
                        IsNewRegion: false,
                      }));
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;

                    // Normalize the inputValue to lowercase
                    const normalizedInputValue = inputValue.toLowerCase();

                    // Check if the inputValue matches any existing option (case-insensitive)
                    const isExisting = options.some(
                      (option) => option.toLowerCase() === normalizedInputValue
                    );

                    // Suggest the creation of a new value only if it does not already exist
                    if (inputValue !== "" && !isExisting) {
                      filtered.push(`Add "${inputValue}"`);
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="Choose New Region"
                  options={
                    (getcreateTicketDetail &&
                      Object.keys(getcreateTicketDetail?.filters_data)) ||
                    []
                  }
                  getOptionLabel={(option) => {
                    // Regular option
                    if (typeof option === "string") {
                      return option;
                    }
                    return option.toString();
                  }}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  sx={{ width: "100%" }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="Choose New Region*" />
                  )}
                />

                {/* choose date  */}
                {/* <div className="date d-block"> */}
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={
                    {
                      // " & .MuiInputBase-input":{
                      //   padding:"15px"
                      // }
                    }
                  }
                >
                  <DatePicker
                    // name="date"
                    value={machineInfo?.date}
                    onChange={(date) => {
                      setMachineInfo((prev) => ({
                        ...prev,
                        date: date,
                      }));
                    }}
                  />
                </LocalizationProvider>
                {/* </div> */}

                {/* Shift by */}
                <Autocomplete
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      // padding: "5px",
                      width: "100%",
                    },
                  }}
                  disablePortal
                  size="medium"
                  id="Shifted By"
                  options={shifted_by_options || []}
                  value={machineInfo?.shifted_by}
                  renderInput={(params) => (
                    <TextField {...params} label="Shifted By" />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setMachineInfo((prev) => ({
                        ...prev,
                        shifted_by: newValue,
                      }));
                    } else {
                      setMachineInfo((prev) => ({
                        ...prev,
                        shifted_by: "",
                      }));
                    }
                  }}
                />

                {/* Add address  */}
                <TextField
                  value={machineInfo?.address}
                  fullWidth
                  id="outlined-basic"
                  label="Add Address"
                  variant="outlined"
                  onChange={(event) => {
                    setMachineInfo((prev) => ({
                      ...prev,
                      address: event.target.value,
                    }));
                  }}
                />

                {/* Add remark  */}
                <TextField
                  value={machineInfo?.remark}
                  fullWidth
                  id="outlined-basic"
                  label="Add remark*"
                  variant="outlined"
                  onChange={(event) => {
                    setMachineInfo((prev) => ({
                      ...prev,
                      remark: event.target.value,
                    }));
                  }}
                />

                {/* Add visit_id  */}
                <TextField
                  value={machineInfo?.visit_id}
                  fullWidth
                  type="number"
                  id="outlined-basic"
                  label="Visit Id"
                  variant="outlined"
                  onChange={(event) => {
                    setMachineInfo((prev) => ({
                      ...prev,
                      visit_id: event.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mt-4">
                <LoadingButton
                  size="small"
                  onClick={submitForm}
                  disabled={
                    machineInfo?.code === "" ||
                    machineInfo?.old_location === "" ||
                    machineInfo?.new_location === "" ||
                    machineInfo?.new_district === "" ||
                    machineInfo?.new_region === "" ||
                    machineInfo?.remark === ""
                  }
                  endIcon={<SendIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                  style={{ height: "36px" }}
                >
                  <span>Submit</span>
                </LoadingButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UpdateMachine;
